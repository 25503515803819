import Api from '../Api';

export const REQUEST_DEPARTEMENTS = 'REQUEST_DEPARTEMENTS';
export const REQUEST_DEPARTEMENTS_SUCCESS = 'REQUEST_DEPARTEMENTS_SUCCESS';
export const REQUEST_DEPARTEMENTS_ERROR = 'REQUEST_DEPARTEMENTS_ERROR';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS';
export const REQUEST_CATEGORIES_ERROR = 'REQUEST_CATEGORIES_ERROR';

export const REQUEST_OFFICES = 'REQUEST_OFFICES';
export const REQUEST_OFFICES_SUCCESS = 'REQUEST_OFFICES_SUCCESS';
export const REQUEST_OFFICES_ERROR = 'REQUEST_OFFICES_ERROR';

export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS';

export const DISMISS_ERROR_MESSAGE = 'DISMISS_ERROR_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

const Errors = {
    SERVICE_UNAVAILABLE: {
        id: 'SERVICE_UNAVAILABLE',
        message: 'Service indisponible'
    }
};

const requestDepartements = () => {
    return {
        type: REQUEST_DEPARTEMENTS,
    }
}

const requestDepartementsSuccess = (departements) => {
    return {
        type: REQUEST_DEPARTEMENTS_SUCCESS,
        departements
    }
}

const requestDepartementsError = (error) => {
    return {
        type: REQUEST_DEPARTEMENTS_ERROR,
        error
    }
}

const requestCategories = () => {
    return {
        type: REQUEST_CATEGORIES,
    }
}

const requestCategoriesSuccess = (categories) => {
    return {
        type: REQUEST_CATEGORIES_SUCCESS,
        categories
    }
}

const requestCategoriesError = (error) => {
    return {
        type: REQUEST_CATEGORIES_ERROR,
        error
    }
}

const requestOffices = () => {
    return {
        type: REQUEST_OFFICES,
    }
}

const requestOfficesSuccess = (offices) => {
    return {
        type: REQUEST_OFFICES_SUCCESS,
        offices
    }
}

const requestOfficesError = (error) => {
    return {
        type: REQUEST_OFFICES_ERROR,
        error
    }
}

const addErrorMessage = (error) => {
    return {
        type: ADD_ERROR_MESSAGE,
        error
    }
}


export const fetchDepartements = _ => (dispatch, getState) =>
    !getState().fetchingDepartements && getState().departements.length === 0
        ? dispatch(requestDepartements()) && Api.departements.list()
            .then(res => dispatch(requestDepartementsSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage(Errors.SERVICE_UNAVAILABLE))
                return dispatch(requestDepartementsError(error))
            })
        : dispatch(requestDepartements()) && new Promise((resolve, reject) => resolve(dispatch(requestDepartementsSuccess(getState().departements))));

export const fetchOffices = _ => (dispatch, getState) =>
    !getState().fetchingOffices && getState().offices.length === 0
        ? dispatch(requestOffices()) && Api.offices.list()
            .then(res => dispatch(requestOfficesSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage(Errors.SERVICE_UNAVAILABLE))
                return dispatch(requestOfficesError(error))
            })
        : dispatch(requestOffices()) && new Promise((resolve, reject) => resolve(dispatch(requestOfficesSuccess(getState().offices))));

export const fetchCategories = _ => (dispatch, getState) =>
    !getState().fetchingCategories && getState().categories.length === 0
        ? dispatch(requestCategories()) && Api.categories.list()
            .then(res => dispatch(requestCategoriesSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage(Errors.SERVICE_UNAVAILABLE))
                return dispatch(requestCategoriesError(error))
            })
        : dispatch(requestCategories()) && new Promise((resolve, reject) => resolve(dispatch(requestCategoriesSuccess(getState().categories))));

export const setSearchTerms = searchTerms => dispatch => {
    return dispatch({
        type: SET_SEARCH_TERMS,
        searchTerms
    })
}

export const dismissErrorMessage = id => dispatch => {
    return dispatch({
        type: DISMISS_ERROR_MESSAGE,
        id
    })
}