import {
    REQUEST_DEPARTEMENTS,
    REQUEST_DEPARTEMENTS_ERROR,
    REQUEST_DEPARTEMENTS_SUCCESS,

    REQUEST_CATEGORIES,
    REQUEST_CATEGORIES_ERROR,
    REQUEST_CATEGORIES_SUCCESS,
    
    REQUEST_OFFICES,
    REQUEST_OFFICES_ERROR,
    REQUEST_OFFICES_SUCCESS,

    SET_SEARCH_TERMS,

    DISMISS_ERROR_MESSAGE,
    ADD_ERROR_MESSAGE,
} from './actions';

const INITIAL_STATE = {
    categories: [],
    departements: [],
    offices: [],
    fetchingDepartements: false,
    fetchingCategories: false,
    searchTerms: [],
    errorMessages: [],
    specialities: [],
}

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_DEPARTEMENTS: 
            return {
                ...state,
                fetchingDepartements: true,
            }
        case REQUEST_DEPARTEMENTS_SUCCESS:
            return {
                ...state,
                departements: action.departements,
                fetchingDepartements: false,
            }
        case REQUEST_DEPARTEMENTS_ERROR: {
            return {
                ...state,
                fetchingDepartements: false,
            }   
        }
        case REQUEST_CATEGORIES: 
            return {
                ...state,
                fetchingCategories: true,
            }
        case REQUEST_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
                fetchingCategories: false,
            }
        case REQUEST_CATEGORIES_ERROR: {
            return {
                ...state,
                fetchingCategories: false,
            }   
        }
        case REQUEST_OFFICES: 
            return {
                ...state,
                fetchingOffices: true,
            }
        case REQUEST_OFFICES_SUCCESS:
            return {
                ...state,
                offices: action.offices,
                specialities: [...new Set(action.offices.map(t => t.specialities).reduce((acc, val) => acc.concat(val), [])
                    .filter(a => a != undefined)
                    .sort())],
                fetchingOffices: false,
            }
        case REQUEST_OFFICES_ERROR: {
            return {
                ...state,
                fetchingOffices: false,
            }   
        }
        case SET_SEARCH_TERMS: {
            return {
                ...state,
                searchTerms: action.searchTerms
            }
        }
        case ADD_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessages: [...state.errorMessages.filter(errorMessage => errorMessage.id !== action.error.id), action.error]
            }
        }
        case DISMISS_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessages: state.errorMessages.filter(errorMessage => errorMessage.id !== action.id)
            }
        }
        default:
            return state
    }
}

export default appReducer;