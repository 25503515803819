import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Grid, IconButton, Typography, Collapse } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import HomeIcon from './HomeIcon';
import RouterLinkRef from './RouterLinkRef';
import { fetchOffices } from '../redux/actions';
import { connect } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MailIcon from '@material-ui/icons/Mail';
import LinkIcon from '@material-ui/icons/Link';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScheduleIcon from '@material-ui/icons/Schedule';

const categoriesImages = {
    "médical et soins infirmiers": "medical-2x.png",
    "dentaire et orthodontie": "dentaire-2x.png",
    "optique": "optique-2x.png",
    "audition": "audition-2x.png",
    "personnes âgées": "personnes_agees-2x.png",
    "personnes handicapées": "personnes_handicapees-2x.png",
    "logement / pharmacie": "logements-2x.png"
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#25343C",
        paddingRight: 0,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat"
    },
    knowMoreButton: {
        width: "100%",
        fontWeight: "normal",
        textTransform: "none",
        zIndex: 1,
        textAlign: "center",
        color: "#04CDD9",
        fontSize: "15px",
        height: "22px",
        padding: 0,
        margin: 0,
        position: "relative",
        top: "9px"
    },
    officeName: {
        fontWeight: "bold",
        fontSize: "20px",
        color: "#FF1C57",
        letterSpacing: "0.36px",
        lineHeight: "24px",
    },
    addressDiv: {
        borderTop: "1px solid #EEEEEE",
        borderBottom: "1px solid #EEEEEE",
        color: "#25343C",
        fontSize: "14px",
        letterSpacing: "0.25px",
        padding: "10px 0",
        marginBottom: "20px"
    },
    buttonRdv: {
        backgroundImage: "linear-gradient(92deg, #FF1C57 0%, #8E005A 100%)",
        padding: "10px 16px",
        width: "100%",
        fontSize: "18px",
        color: "#FFFFFF",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "28px",
        fontWeight: "normal"
    }
});

class Office extends Component {

    constructor(props) {
        super(props);

        const queryParam = queryString.parse(this.props.location.search);
        this.state = {
            seeMore: false,
            hoursCollapsed: true,
        }

        props.fetchOffices().then(() => {
            this.setState({
                office: this.props.offices.filter(office => office._id == queryParam.id)[0]
            });
        });
    }


    componentDidMount = () => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "white";
    }

    goBack = () => {
        this.props.history.push("/search-results");
    }

    toggleSeeMore = () => {
        this.setState({
            seeMore: !this.state.seeMore
        });
    }

    openGoogleMap = () => {
        const address = this.state.office.address1 + (this.state.office.address2 ? "+" + this.state.office.address2 : "");
        const city = this.state.office.postalcode + " " + this.state.office.city;
        window.open("https://www.google.com/maps/place/" + address.replace(/[\s]+/gi, "+") + "," + city.replace(/[\s]+/gi, "+"), "_blank");
    }

    openPhone = () => {
        window.location.href = "tel:" + this.state.office.phone;
    }

    openEmail = () => {
        window.location.href = "mailto:" + this.state.office.email;
    }

    openWebsite = () => {
        window.open(this.state.office.knowMore, "_blank");
    }

    toggleHoursCollapse = () => {
        this.setState({
            hoursCollapsed: !this.state.hoursCollapsed
        })
    }

    getCategoryImage = (office) => {
        if(!office.category || !categoriesImages[office.category.toLowerCase()]) {
            return categoriesImages["médical et soins infirmiers"];
        }
        return categoriesImages[office.category.toLowerCase()]; 
    }

    render() {
        const { classes, history } = this.props;
        const office = this.state.office;
        return !office
            ? <div></div>
            : <div>
                <div position="static" className={classes.appBar} style={{ width: "100%", backgroundImage: "url('" + this.getCategoryImage(office) + "')" }} >
                    <div style={{ height: "auto", color: "white" }}>
                        <Grid container>
                            <Grid item xs={2} style={{ textAlign: "center" }}>
                                <IconButton onClick={() => this.goBack()} edge="start" color="inherit" aria-label="menu">
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h5" style={{ lineHeight: "1.1em", paddingTop: "9px", textAlign: "center", fontWeight: "bold" }}>
                                    {office.category}
                                </Typography>

                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <IconButton style={{padding: 0, left: "-11px", top: "5px"}} component={RouterLinkRef} to="/" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                    <HomeIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={1} direction="column" alignItems="center">
                        <Grid item>
                            {this.state.seeMore
                                ? <IconButton style={{ padding: "4px", fontSize: "10px", textAlign: "center", marginTop: "10px" }} onClick={() => this.toggleSeeMore()}>
                                    <CancelIcon style={{ color: "#04CDD9" }} />
                                </IconButton>
                                : <Button className={classes.knowMoreButton} onClick={() => this.toggleSeeMore()}>En savoir plus</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state.seeMore
                        ? <div>
                            <Grid container spacing={4} direction="column" alignItems="center">
                                <Grid item style={{ padding: "20px 40px" }}>
                                    <Typography variant="body1" style={{ lineHeight: "1.4em", color: "white", paddingTop: "8px", textAlign: "center" }}>{office.description}</Typography>
                                </Grid>
                                <div style={{ width: "100%", overflow: "hidden", height: "30px" }}>
                                    <svg style={{ width: "100%", height: "50px", position: "relative", top: "3px" }} viewBox="0 0 100 50" preserveAspectRatio="none">
                                        <path fill="white" d="M 0,50 V 0 Q 50,30 100,0 V 50"></path>
                                    </svg>
                                </div>
                            </Grid>
                        </div>
                        : <div>
                            <div style={{ width: "100%", overflow: "visible" }}>
                                <svg style={{ width: "100%", height: "50px", position: "relative", top: "5px" }} viewBox="0 0 100 50" preserveAspectRatio="none">
                                    <path fill="white" d="M -10,50 Q 50,20 110,50"></path>
                                </svg>
                            </div>
                        </div>}
                </div>
                <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item style={{ padding: "10px 40px", width: "100%" }}>
                        <Grid item xs={12}>
                            <p className={classes.officeName}>{office.name}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0} className={classes.addressDiv} >
                                <Grid item xs={10}>
                                    <p style={{ margin: 0 }}>{office.address1}</p>
                                    {office.address2 && office.address2.length > 0 && <p style={{ margin: 0 }}>{office.address2}</p>}
                                    <p style={{ margin: 0 }}>{office.postalcode} {office.city}</p>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton style={{ padding: "6px", fontSize: "12px", backgroundColor: "#04CDD9" }} onClick={() => this.openGoogleMap()}>
                                        <RoomIcon style={{ color: "white" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {office.rdv && <Grid item style={{ textAlign: "center", margin: "10px 0" }} xs={12}>
                            <Button href={office.rdv} target="_blank" className={classes.buttonRdv}>Prendre RDV</Button>
                        </Grid>}

                        {office.phone && <Grid item xs={12} onClick={() => this.openPhone()}>
                            <Grid container spacing={0} style={{ margin: "5px 0px", backgroundColor: "#f9f9f9", padding: "14px 6px 6px 10px" }}  >
                                <Grid item xs={1}>
                                    <PhoneIcon style={{ color: "#25343C" }} fontSize="small" />
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{ padding: "0 0 0 10px", margin: 0, fontSize: "14px", color: "#25343C", letterSpacing: "0.25px" }}>{office.phone}</p>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton style={{ color: "black", padding: 0 }}>
                                        <ChevronRightIcon style={{ color: "#FF1C57" }} fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>}
                        {office.email && <Grid item xs={12} onClick={() => this.openEmail()}>
                            <Grid container spacing={0} style={{ margin: "5px 0px", backgroundColor: "#f9f9f9", padding: "14px 6px 6px 10px" }}  >
                                <Grid item xs={1}>
                                    <MailIcon style={{ color: "#25343C" }} fontSize="small" />
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{ padding: "0 0 0 10px", margin: 0, fontSize: "12px", color: "#25343C", letterSpacing: "0.25px" }}>{office.email}</p>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton style={{ color: "black", padding: 0 }}>
                                        <ChevronRightIcon style={{ color: "#FF1C57" }} fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>}
                        {office.knowMore && <Grid item xs={12} onClick={() => this.openWebsite()}>
                            <Grid container spacing={0} style={{ margin: "5px 0px", backgroundColor: "#f9f9f9", padding: "14px 6px 6px 10px" }}  >
                                <Grid item xs={1}>
                                    <LinkIcon style={{ color: "#25343C" }} fontSize="small" />
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{ padding: "0 0 0 10px", margin: 0, fontSize: "12px", color: "#25343C", letterSpacing: "0.25px" }}>Site web</p>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton style={{ color: "black", padding: 0 }}>
                                        <ChevronRightIcon style={{ color: "#FF1C57" }} fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {office.hours && <Grid item xs={12} onClick={() => this.toggleHoursCollapse()}>
                            <Grid container spacing={0} style={{ margin: "20px 0", padding: "14px 6px 6px 10px", borderTop: "1px solid rgb(249, 249, 249)", borderBottom: "1px solid rgb(249, 249, 249)" }}  >
                                <Grid item xs={1}>
                                    <ScheduleIcon style={{ color: "#25343C" }} fontSize="small" />
                                </Grid>
                                <Grid item xs={9}>
                                    <p style={{ padding: "0 0 0 10px", margin: 0, fontSize: "14px", color: "#25343C", letterSpacing: "0.25px" }}>Horaires</p>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton style={{ color: "black", padding: 0 }}>
                                        {this.state.hoursCollapsed
                                            ? <KeyboardArrowDownIcon style={{ color: "#25343C" }} fontSize="small" />
                                            : <KeyboardArrowUpIcon style={{ color: "#25343C" }} fontSize="small" />}
                                    </IconButton>
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "8px" }}>
                                    <Collapse in={!this.state.hoursCollapsed}>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px", fontColor: "#25343C" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Lundi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.monday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Mardi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.tuesday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Mercredi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.wednesday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Jeudi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.thursday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Vendredi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.friday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Samedi</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.saturday || "-"}</Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{  backgroundColor: "#F9F9F9", margin: "3px 0", padding: "7px 0", fontSize: "12px" }}  >
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3} style={{ fontWeight: "bold" }}>Dimanche</Grid>
                                            <Grid item xs={8} style={{}}>{office.hours.sunday || "-"}</Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                        }

                    </Grid>
                </Grid>

            </div >
    }
}


const mapStateToProps = state => {
    return {
        offices: state.offices
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOffices: () => dispatch(fetchOffices()),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(withStyles(styles)(Office
    )));