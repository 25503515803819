import React, { Component } from 'react';
import { Grid, Button, Chip } from '@material-ui/core';
import TopBar from './TopBar';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

import { withStyles } from '@material-ui/core/styles';
import { fetchDepartements, fetchCategories, fetchOffices } from '../redux/actions';
import { connect } from 'react-redux';

const styles = theme => ({
    paper: {
        padding: "1px 14px 1px 14px",
        borderRadius: "3px !important",
        marginBottom: "16px",
    },
    paperTitle: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#FF1C57",
        letterSpacing: "0.27px",
    },
    paperAddress: {
        fontSize: "13px",
        color: "#25343C",
        letterSpacing: "0.24px",
        margin: "4px"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),

    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapseGrid: {
        borderTop: "1px solid #EEEEEE",
        borderBottom: "1px solid #EEEEEE",
        padding: "10px 0",
        margin: "10px 0",
    },
    badge: {
        backgroundColor: "#F1F1F1",
        color: "#555555",
        margin: "3px",
        borderRadius: "2px",
        fontSize: '11px',
        height: "22px",
        padding: "5px 6px"
    },
    buttonRdv: {
        backgroundImage: "linear-gradient(92deg, #FF1C57 0%, #8E005A 100%)",
        color: "white",
        fontSize: "13px",
        padding: "4px 12px",
        marginRight: "12px"
    },
    buttonOpen: {
        color: "#FF1C57",
        padding: "4px 16px",
    },
    resultsLabel: {
        color: "#888888",
        fontFamily: "Helvetica",
        fontSize: "18px",
        padding: "0",
    },
    chip: {
        fontSize: "12px",
        margin: "2px",
        paddingTop: 0,
        paddingBottom: 0,
    }
});

class SearchResultsPage extends Component {


    constructor(props) {
        super(props);
        const queryParam = queryString.parse(this.props.location.search);
        this.state = {
            departementId: queryParam && queryParam.location ? queryParam.location : "",
            offices: [],
            collapsable: [],
            expanded: [],
            specialities: [],
            selectedSpecialities: [],
            showFilterPopup: false,
            topBarMainText: "",
            topBarSubText: ""
        };
        this.collapseContentRefs = [];


        Promise.all([
            props.fetchOffices(),
            props.fetchCategories(),
            props.fetchDepartements()
        ]).then(() => {
            let offices = this.props.offices;
            let topBarMainText = "";
            let topBarSubText = "";
            const departementFilter = this.props.searchTerms.filter(t => t.type === 'departement')[0];
            const officeFilter = this.props.searchTerms.filter(t => t.type === 'office')[0];
            const categoryFilter = this.props.searchTerms.filter(t => t.type === 'category')[0];
            const specialityFilter = this.props.searchTerms.filter(t => t.type === 'speciality')[0];
            if (departementFilter) {
                offices = offices.filter(o => o.departement && o.departement.id == departementFilter.id);
                topBarSubText = departementFilter.name;
            }
            if (officeFilter) {
                offices = offices.filter(o => o._id == officeFilter._id);
                topBarMainText = officeFilter.name;
            }
            if (categoryFilter) {
                offices = offices.filter(o => o.category && o.category.toLowerCase() === categoryFilter.name.toLowerCase());
                topBarMainText = categoryFilter.name;
            }
            if (specialityFilter) {
                offices = offices.filter(o => o.specialities && !!o.specialities.find(s => s === null ? "" : s.toLowerCase() === specialityFilter.name.toLowerCase()));
                topBarMainText = specialityFilter.name;
            }

            const specialities = [... new Set(offices.map(o => o.specialities).reduce((acc, val) => acc.concat(val), []))].sort();

            this.collapseContentRefs = offices.map(() => React.createRef());
            this.setState({
                specialities,
                topBarMainText,
                topBarSubText,
                offices: offices,
                collapsable: offices.map(() => false),
                expanded: offices.map(() => false),
                departements: this.props.departements,
                speciality: officeFilter ? officeFilter.name : (categoryFilter ? categoryFilter.name : ""),
                departement: departementFilter ? departementFilter.name : ""
            });
            setTimeout(() => {
                this.setState({
                    collapsable: this.collapseContentRefs.map((ref, index) => ref.current && ref.current.scrollHeight > 58)
                });
            }, 100);
        });
    }

    componentDidMount = () => {
        document.body.style.overflow = "auto";
        document.body.style.backgroundColor = "white";
    }

    toggleSpecialityFilter = (speciality) => {
        if (this.state.selectedSpecialities.indexOf(speciality) === -1) {
            this.setState({
                selectedSpecialities: [...this.state.selectedSpecialities, speciality]
            });
        } else {
            this.setState({
                selectedSpecialities: this.state.selectedSpecialities.filter(s => s !== speciality)
            });
        }
    }
    cancelFiltering = () => {
        this.setState({
            selectedSpecialities: [],
           // showFilterPopup: false
        });
    }

    render() {
        const { classes } = this.props;
        const handleExpandClick = (index) => {
            const newExpanded = [...this.state.expanded];
            newExpanded[index] = !newExpanded[index];
            this.setState({ expanded: newExpanded });
        };

        const filtering = this.state.selectedSpecialities.length > 0;
        const filteredOffices = filtering
            ? this.state.offices
                .filter(office => {
                    return office.specialities.filter(speciality => this.state.selectedSpecialities.indexOf(speciality) !== -1).length > 0;
                })
            : this.state.offices;

        const allResultsCount = this.state.offices.length;

        return <div style={{ backgroundColor: "#EEE", minHeight: "100%" }}>
            <TopBar mainText={this.state.topBarMainText} subText={this.state.topBarSubText} back={"/search"} />
            
            <Grid container style={{ width: "90%", margin: "5%", padding: 0, marginTop: "10px" }} direction="row" alignItems="center" spacing={1} >
                <Grid item xs={8}>
                    <div className={classes.resultsLabel}>{allResultsCount} résultat{allResultsCount > 1 && "s"} {filtering && "(" + filteredOffices.length + " filtrés)"}</div>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                    {this.state.specialities.length > 0 && !this.state.showFilterPopup &&
                        <Button style={{ padding: 0 }} onClick={() => this.setState({ showFilterPopup: !this.state.showFilterPopup })}>Filtrer</Button>
                    }
                </Grid>
                {this.state.showFilterPopup &&
                    <Grid item xs={12}>
                        <Paper style={{ padding: "12px" }}>
                            <Grid container>
                                <Grid item xs={2} style={{ textAlign: "right" }}></Grid>
                                <Grid item xs={8} style={{ textAlign: "center" }}>
                                    <Button onClick={() => this.cancelFiltering()} disabled={this.state.selectedSpecialities.length === 0} style={{ position: "relative", top: "-7px" }}>Éffacer les filtres</Button>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton style={{ position: "relative", top: "-21px", right: "-21px", padding: 0 }} onClick={() => this.setState({ showFilterPopup: !this.state.showFilterPopup })}><CancelIcon style={{ color: "#FF1C57" }} /></IconButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "left" }}>
                                {this.state.specialities.map(speciality =>
                                    <Chip className={classes.chip} size="small" clickable color={this.state.selectedSpecialities.indexOf(speciality) !== -1 ? "primary" : ""} deleteIcon={<DoneIcon />} onDelete={() => { }} onClick={() => this.toggleSpecialityFilter(speciality)} label={speciality} key={speciality}></Chip>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                }

                <Grid item xs={12}>
                    {
                        filteredOffices
                            .map((result, index) =>
                                <Paper className={classes.paper} key={index}>
                                    <p className={classes.paperTitle}>{result.name}</p>
                                    <p className={classes.paperAddress}>{result.address1}</p>
                                    <p className={classes.paperAddress}>{result.postalcode} {result.city}</p>
                                    {result.specialities.length > 0
                                        ? <Grid className={classes.collapseGrid} container direction="row" alignItems="center" spacing={0} >
                                            <Grid item xs={11}>
                                                <div className="specialityChipDiv" ref={this.collapseContentRefs[index]} style={{ overflow: "hidden", maxHeight: this.state.expanded[index] ? "unset" : "58px" }}>
                                                    {result.specialities.map((speciality, index) =>
                                                        <Chip key={index} className={classes.badge} label={speciality}></Chip>
                                                    )}

                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                {this.state.collapsable[index] && <IconButton
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: this.state.expanded[index],
                                                    })}
                                                    onClick={() => handleExpandClick(index)}
                                                    aria-expanded={this.state.expanded[index]}
                                                    aria-label="show more"><ExpandMoreIcon />
                                                </IconButton>}
                                            </Grid>
                                        </Grid>
                                        : <div style={{ height: "20px" }}></div>
                                    }

                                    <Grid style={{ marginBottom: "12px" }} container direction="row" alignItems="center" spacing={0} >
                                        <Grid item style={{ textAlign: "left" }} xs={6}>
                                            <Button onClick={() => this.props.history.push("/office?id=" + result._id)} className={classes.buttonOpen}>Voir</Button>
                                        </Grid>
                                        <Grid item style={{ textAlign: "right" }} xs={6}>
                                            {result.rdv && <Button href={result.rdv} target="_blank" className={classes.buttonRdv}>Prendre RDV</Button>}
                                        </Grid>
                                    </Grid>

                                </Paper>
                            )
                    }
                </Grid>
            </Grid>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        categories: state.categories,
        departements: state.departements,
        offices: state.offices,
        searchTerms: state.searchTerms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDepartements: () => dispatch(fetchDepartements()),
        fetchCategories: () => dispatch(fetchCategories()),
        fetchOffices: () => dispatch(fetchOffices()),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(withStyles(styles)(SearchResultsPage
    )));