import React, { Component } from 'react';
import { dismissErrorMessage } from './redux/actions';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import ErrorSnack from './components/ErrorSnack';

class AppContainer extends Component {


    render() {
        return <Container id="container" maxWidth="sm" style={{ padding: 0, height: "100%" }}>
            {this.props.children}
            { this.props.errorMessages.map((message => <ErrorSnack key={message.id} message={message.message} onClose={() => this.props.dismissErrorMessage(message.id)}/>))}
        </Container>
    }
}


const mapStateToProps = state => {
    return {
        errorMessages: state.errorMessages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dismissErrorMessage: (id) => dispatch(dismissErrorMessage(id)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppContainer);