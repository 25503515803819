import React, { Component } from 'react';
import { Button } from '@material-ui/core';

const styles = {

    button: {
        width: "100%",
        height: "60px"
    },
    img: {
        position: "absolute",
        left: "10px",
        width: "28px",
    },
    label: {
        textTransform: "none",
        lineHeight: "1.3em",
        textAlign: "center",
        fontSize: "0.95em",
        paddingLeft: 35,
        paddingRight: 3,

    }
};

class HomeButton extends Component {
    render() {
        return <Button {...this.props} style={{ ...styles.button, backgroundColor: this.props.color }} onClick={this.props.onClick}  variant="contained" color="primary" >
            <img style={styles.img} alt={this.props.label} src={this.props.image} />
            <span style={styles.label}>{this.props.label}</span>
        </Button>
    }
}

export default (HomeButton);