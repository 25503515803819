import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxHeight: 40,
        overflow: "hidden"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        backgroundColor: "#17313A",
        color: "white",
        height: 50,
        width: 50,
        left: 20,
        borderRadius: "40px"
    },
    iconButtonRight: {
        backgroundColor: "rgb(23, 49, 58)",
        width: "50px",
        height: "50px",
        top: "-30px",
        left: "20px",
        position: "relative"
    },
    searchButton: {
        position: "relative",
        left: "17px",
        top: "13px",
        zIndex: 2
    }
})

class HomeSearch extends Component {
    render() {
        const {
            classes,
        } = this.props;

        return <Paper className={classes.root} onClick={this.props.disabled ? undefined : this.props.onClick }>
            <InputBase
                disabled={this.props.disabled}
                className={classes.input}
                placeholder="Spécialité, établissement..."
                inputProps={{ 'aria-label': 'search' }}
            />
            <div className={classes.iconButton} aria-label="search">
                <SearchIcon className={classes.searchButton} />
                <div className={classes.iconButtonRight}>&nbsp;</div>
            </div>
        </Paper>
    }
}

export default withStyles(styles)(
    HomeSearch
);