import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


class HomeIcon extends Component {
    render() {
        return <SvgIcon {...this.props} style={{width:"50px", height:"50px", ...this.props.style}} >
            <svg viewBox="0 0 45 50" version="1.1" >
                <g id="Appli" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="02" transform="translate(-315.000000, -36.000000)" fill="#FFFFFF">
                        <g id="Group-7" transform="translate(315.000000, 36.000000)">
                            <g id="Group-66" opacity="0.2">
                                <path d="M34.1100117,7.7526 C34.106,9.0286 33.109,10.0286 31.832,10.0376444 C30.587,10.0456 29.522,8.9706 29.533,7.7156 C29.543,6.4716 30.579,5.4436 31.834,5.4306 C33.096,5.4186 34.114,6.4566 34.1100117,7.7526" id="Fill-1"></path>
                                <path d="M44.9434933,24.7541 C44.9465,23.5011 45.9825,22.4711 47.2355,22.4770739 C48.4945,22.4821 49.5335,23.5391 49.5235,24.8051 C49.5135,26.0451 48.4465,27.1021 47.2205,27.0851 C45.9425,27.0681 44.9405,26.0421 44.9434933,24.7541" id="Fill-3"></path>
                                <path d="M18.1096353,21.0988 C18.1026,22.3548 17.0296,23.4118 15.7786,23.3938 C14.4966,23.3758 13.5256,22.3608 13.5325624,21.0448 C13.5396,19.7788 14.5726,18.7638 15.8336,18.7858 C17.0806,18.8068 18.1166,19.8588 18.1096353,21.0988" id="Fill-5"></path>
                                <path d="M11.7941007,24.7351 C11.7951,26.0211 10.7771,27.0421 9.4981,27.0361263 C8.2471,27.0301 7.2171,25.9971 7.21509342,24.7461 C7.2121,23.4681 8.2351,22.4521 9.5221,22.4560882 C10.7991,22.4591 11.7931,23.4561 11.7941007,24.7351" id="Fill-7"></path>
                                <path d="M8.8459,42.8869066 C7.56989926,42.8839 6.56989926,41.8839 6.56989926,40.6119 C6.5689,39.3199 7.5759,38.3079 8.8619,38.3088993 C10.1149,38.3099 11.1449,39.3389 11.1479029,40.5919 C11.1499,41.8749 10.1319,42.8899 8.8459,42.8869066" id="Fill-9"></path>
                                <path d="M14.1227,37.6301121 C12.8527,37.6261 11.8516993,36.6181 11.8516993,35.3421 C11.8507,34.1101 12.9057,33.0601 14.1497,33.0540657 C15.4007,33.0471 16.4617,34.1121 16.4577113,35.3701 C16.4527,36.6431 15.4297,37.6341 14.1227,37.6301121" id="Fill-11"></path>
                                <path d="M35.405,16.4514007 C34.15,16.4524 33.122,15.4384 33.1,14.1794 C33.079,12.9394 34.148,11.8694 35.408,11.8703993 C36.661,11.8724 37.697,12.8954 37.71,14.1464 C37.724,15.4044 36.678,16.4504 35.405,16.4514007" id="Fill-13"></path>
                                <path d="M41.7551,34.1477121 C40.4891,34.1437 39.4771,33.1297 39.4750971,31.8657 C39.4731,30.6017 40.5201,29.5627 41.7871,29.5696648 C43.0651,29.5767 44.0661,30.6027 44.0561,31.8957 C44.0471,33.1637 43.0361,34.1517 41.7551,34.1477121" id="Fill-15"></path>
                                <path d="M8.9099,11.1218063 C7.6489,11.1248 6.5829,10.0628 6.5979,8.8198 C6.6119,7.5708 7.6509,6.5418 8.8979,6.5408 C10.1709,6.5408 11.2179,7.5838 11.2069,8.8438 C11.1959,10.0968 10.1659,11.1188 8.9099,11.1218063" id="Fill-17"></path>
                                <path d="M34.0392007,41.8264 C34.0402,43.1214 33.0302,44.1374 31.7512,44.130436 C30.5082,44.1234 29.4612,43.0724 29.4621993,41.8354 C29.4621993,40.5914 30.5042,39.5374 31.7412,39.5294 C33.0262,39.5204 34.0382,40.5324 34.0392007,41.8264" id="Fill-19"></path>
                                <path d="M36.0236029,28.4851 C36.0216,29.7431 35.0116,30.7631 33.7486,30.7811 C32.5076,30.7991 31.4406,29.7301 31.4435937,28.4711 C31.4466,27.2231 32.4806,26.1831 33.7266,26.1750542 C34.9896,26.1671 36.0256,27.2091 36.0236029,28.4851" id="Fill-21"></path>
                                <path d="M27.0046007,47.2092 C27.0036,48.5062 26.0056,49.4962 24.7066,49.4922121 C23.4406,49.4882 22.4306,48.4762 22.4275885,47.2062 C22.4236,45.9672 23.4666,44.9182 24.7096,44.9121736 C25.9896,44.9062 27.0056,45.9242 27.0046007,47.2092" id="Fill-23"></path>
                                <path d="M40.6779,6.60759922 C41.9479,6.6086 42.9739,7.6176 42.9779112,8.8696 C42.9819,10.1226 41.9149,11.1936 40.6719,11.1846 C39.3869,11.1756 38.3989,10.1656 38.4018932,8.8636 C38.4049,7.5716 39.3789,6.6066 40.6779,6.60759922" id="Fill-25"></path>
                                <path d="M23.3762029,33.738 C23.3782,35.009 22.3312,36.056 21.0722,36.043 C19.8172,36.031 18.7962,35.002 18.7951993,33.747 C18.7942,32.479 19.8482,31.424 21.0992,31.441 C22.3592,31.458 23.3742,32.483 23.3762029,33.738" id="Fill-27"></path>
                                <path d="M17.7204,9.98560663 C16.4374,9.9886 15.4124,8.9736 15.4103971,7.6986 C15.4084,6.4646 16.4584,5.4186 17.7014,5.41458831 C18.9494,5.4106 19.9924,6.4336 20.0114,7.6806 C20.0314,8.9446 18.9994,9.9826 17.7204,9.98560663" id="Fill-29"></path>
                                <path d="M27.0759123,2.3029 C27.0719,3.5669 26.0539,4.5829 24.7949,4.57791838 C23.5559,4.5729 22.5019,3.5229 22.4978884,2.2909 C22.4939,1.0019 23.5259,-0.0081 24.8389,-0.0001 C26.1099,0.0069 27.0799,1.0049 27.0759123,2.3029" id="Fill-31"></path>
                                <path d="M41.8434,20.0314 C40.6104,20.0784 39.5734,19.0914 39.5024,17.8044 C39.4364,16.5824 40.4644,15.5064 41.7574,15.4444 C42.9634,15.3864 44.0694,16.4584 44.1004,17.7164 C44.1314,18.9494 43.1244,19.9824 41.8434,20.0314" id="Fill-33"></path>
                                <path d="M28.4802,18.0866363 C27.2382,18.0796 26.2212,17.0686 26.2131546,15.8316 C26.2052,14.5756 27.2562,13.5155993 28.5102,13.5155993 C29.7672,13.5146 30.8142,14.5646 30.8142,15.8256 C30.8142,17.0866 29.7742,18.0936 28.4802,18.0866363" id="Fill-35"></path>
                                <path d="M30.8141,33.7517 C30.8011,35.0007 29.7161,36.0657 28.4841,36.0387 C27.2411,36.0127 26.2001,34.9527 26.2141,33.7277 C26.2271,32.4867 27.2711,31.4457 28.5061,31.4396663 C29.7441,31.4327 30.8271,32.5187 30.8141,33.7517" id="Fill-37"></path>
                                <path d="M13.5357993,28.4689 C13.5368,27.1919 14.5238,26.1959 15.8058,26.1769 C17.0268,26.1589 18.0978,27.2269 18.105845,28.4689 C18.1138,29.7029 17.0538,30.7759 15.8238,30.7789066 C14.5398,30.7819 13.5348,29.7669 13.5357993,28.4689" id="Fill-39"></path>
                                <path d="M2.2941,22.4084 C3.5351,22.4084 4.5811,23.4484 4.5951,24.6954 C4.6081,25.9224 3.5411,27.0034 2.3161,27.0034 C1.0431,27.0034 0.0171,25.9834 0.0001,24.7014 C-0.0159,23.4854 1.0611,22.4084 2.2941,22.4084" id="Fill-41"></path>
                                <path d="M38.3390933,40.6574 C38.3361,39.3884 39.3431,38.3774 40.6121,38.3733933 C41.8861,38.3704 42.9031,39.3704 42.9121,40.6354 C42.9211,41.9044 41.8811,42.9514 40.6161,42.9474114 C39.3581,42.9444 38.3421,41.9224 38.3390933,40.6574" id="Fill-44"></path>
                                <path d="M5.4357,17.6701 C5.4357,16.3871 6.4387,15.3701 7.6987,15.3740882 C8.9607,15.3781 10.0187,16.4411 10.0057,17.6911 C9.9927,18.9121 8.9537,19.9531 7.7307,19.9691 C6.4527,19.9861 5.4357,18.9661 5.4357,17.6701" id="Fill-46"></path>
                                <path d="M37.7667971,24.7648 C37.7688,23.5188 38.7768,22.4938 40.0148,22.4798 C41.2718,22.4648 42.3378,23.5158 42.3408113,24.7738 C42.3448,26.0328 41.2858,27.0878 40.0268,27.0808346 C38.7818,27.0738 37.7648,26.0318 37.7667971,24.7648" id="Fill-48"></path>
                                <path d="M21.0724,18.0866067 C19.8044,18.0836 18.7964,17.0716 18.7993933,15.8046 C18.8014,14.5476 19.8284,13.5176 21.0814,13.5155971 C22.3504,13.5136 23.3784,14.5516 23.3724261,15.8316 C23.3664,17.0906 22.3474,18.0896 21.0724,18.0866067" id="Fill-50"></path>
                                <path d="M17.6276,44.0871115 C16.3796,44.0831 15.3396,43.0371 15.3475542,41.7931 C15.3556,40.5421 16.3916,39.5141 17.6426,39.5150993 C18.8956,39.5161 19.9306,40.5411 19.9406,41.7911 C19.9506,43.0521 18.9026,44.0911 17.6276,44.0871115" id="Fill-52"></path>
                                <path d="M22.5051,40.0295 C22.5051,38.7735 23.5121,37.7525 24.7511,37.7534993 C25.9951,37.7555 27.0251,38.7875 27.0381,40.0465 C27.0501,41.2405 25.9761,42.2955 24.7481,42.2965 C23.4981,42.2965 22.5051,41.2925 22.5051,40.0295" id="Fill-54"></path>
                                <path d="M9.93183667,31.8386 C9.9258,33.0796 8.9068,34.0656 7.6398,34.0566 C6.4248,34.0486 5.3758,32.9796 5.3948,31.7676 C5.4148,30.5426 6.4658,29.5146 7.6938,29.5175935 C8.9428,29.5216 9.9388,30.5546 9.93183667,31.8386" id="Fill-56"></path>
                                <path d="M35.402,33.1305992 C36.634,33.1316 37.638,34.1336 37.6420115,35.3646 C37.646,36.5946 36.609,37.6586 35.403,37.6636253 C34.16,37.6696 33.086,36.6076 33.1,35.3876 C33.115,34.1166 34.122,33.1296 35.402,33.1305992" id="Fill-58"></path>
                                <path d="M33.7277,23.3317031 C32.4667,23.3297 31.4647,22.3387 31.4626971,21.0907 C31.4607,19.8317 32.4767,18.8397 33.7617,18.8446812 C35.0157,18.8507 36.0127,19.8567 36.0047,21.1077 C35.9977,22.3527 34.9927,23.3337 33.7277,23.3317031" id="Fill-60"></path>
                                <path d="M27.0558,9.4982 C27.0558,10.7492 26.0528,11.7492 24.7988,11.7462067 C23.5408,11.7432 22.5078,10.7112 22.5188,9.4652 C22.5298,8.2652 23.6028,7.2122 24.8148,7.21319929 C26.0278,7.2142 27.0558,8.2612 27.0558,9.4982" id="Fill-62"></path>
                                <path d="M14.2048,11.8829811 C15.4548,11.888 16.4778,12.915 16.4578,14.145 C16.4368,15.386 15.4018,16.408 14.1788,16.395 C12.9218,16.381 11.9148,15.375 11.9187881,14.138 C11.9228,12.867 12.9258,11.878 14.2048,11.8829811" id="Fill-64"></path>
                            </g>
                            <g id="Group-7" transform="translate(12, 12)">
                                <g opacity="1">
                                    <path d="M 10 20 v -6 h 4 v 6 h 5 v -8 h 3 L 12 3 L 2 12 h 3 v 8 Z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    }
}
export default HomeIcon;