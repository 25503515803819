import settings from './settings';

const fetchJson = (url) => new Promise((resolve, reject) =>
    fetch(url).then(response => {
        resolve(response.json());
    }).catch(err => reject(err))
)

const HOST = settings.apiHost;

const Api = {
    departements: {
        list: () => {
            return fetchJson(HOST + "/departements")
        }
    },
    categories: {
        list: () => {
            return fetchJson(HOST + "/categories")
        }
    },
    offices: {
        list: () => {
            return fetchJson(HOST + "/offices")
        }
    }

};

export default Api;