const configs = {
    dev: {
        env: "dev",
        apiHost: "http://localhost:5000",
    },
    prod: {
        env: "prod",
        apiHost: "/api",
    }
};


export default (document.location.href.indexOf("http://localhost") === -1) ? configs.prod : configs.dev;