import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const theme = {
  container: {
    width: "100%"
  },
  input: {
    width: "100%",
    border: 0,
    borderBottom: "1px solid white",
    fontSize: "1.1em",
    paddingTop: "8px",
    paddingBottom: "8px",
    outlineWidth: 0
  },
  inputFocused: {
    borderBottom: "1px solid #FF1C57"
  },
  suggestionsContainer: {
    position: "absolute",
    top: "190px",
    width: "402px",
    marginLeft: "40px",
    color: "#25343C",
    fontSize: "12px",
    cursor: "pointer",
  },
  suggestion: {
    listStyleType: "none",
    cursor: "pointer",
    height: "auto",
    borderBottom: "1px solid #DDDDDD",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    width: "100%",
    paddingLeft: "10px",
    backgroundColor: "#f9f9f9",
    position: "absolute",
    left: "-10%",
    right: 0
  },
  clearCross: {
    position: "relative",
    float: "left",
    width: "8%"

  }
};


class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || "",
      suggestions: [],
      hasValidValue: false
    };
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.values.filter(lang =>
      lang.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  renderSuggestionsContainer = ({ containerProps , children, query }) => {
    return (
      <div {... containerProps}>
        {children}
      </div>
    );
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return <div
      style={{ width: "100%", cursor: "pointer", padding: "5px"}}
      dangerouslySetInnerHTML={{ __html: suggestion.name.replace(new RegExp(query, 'ig'), (a) => "<strong>" + a + "</strong>") }}
    />
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      hasValidValue: false,
    });
    this.props.onChange(null);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue = suggestion => {
    return suggestion.name;
  }

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onChange(suggestion);
    this.setState({ hasValidValue: true, value: suggestion.name });
  }

  renderInputComponent = inputProps => (
    <Grid container style={{}} direction="row" alignItems="center" spacing={0} >
      <Grid style={{ backgroundColor: "#FFF" }} item xs={11}>
        <input {...inputProps} />
      </Grid>
      <Grid style={{ backgroundColor: "#FFF" }} item xs={1}>
        {this.state.value &&
          <IconButton style={{ padding: "4px" }} onClick={() => this.onChange(null, { newValue: "" })}>
            <CloseIcon style={{ color: "#AAAAAA" }} />
          </IconButton>
        }
      </Grid>
    </Grid>
  );

  valueIsValid = (value) => {
    return this.props.values.find(v => v.name === value);
  }

  storeInputReference = (autosuggest) => {
    if (autosuggest != null) {
      autosuggest.input.onblur = () => {
        setTimeout(() => {
          if (!this.state.hasValidValue) {
            this.props.onChange("");
            autosuggest.input.value = "";
            this.setState({
              value: ""
            });

          }
        }, 100);
      };
    }
  }

  render() {
    const { suggestions } = this.state;

    const inputProps = {
      ...this.props,
      value: this.state.value,
      onChange: this.onChange,
      autoFocus: this.props.autoFocus,
    };


    return (
      <Autosuggest
     //   ref={this.storeInputReference}
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  }
}

export default AutoComplete;