import React, { Component } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import TopBar from './TopBar';
import AutoComplete from './AutoComplete';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { fetchCategories, fetchOffices, fetchDepartements, setSearchTerms } from '../redux/actions';

class SearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryOrOffice: props.searchTerms.category ? null : "",
            location: props.searchTerms.location ? null : "",
            offices: [],
            loading: true,
            searchTerms: []
        }

        this.mainRef = React.createRef();
        this.whiteRef = React.createRef();

        Promise.all([props.fetchDepartements(), props.fetchCategories(), props.fetchOffices()]).then(res => {
            this.setState({
                searchTerms: props.searchTerms,
                loading: false,
            });
        });
    }

    componentDidMount = () => {
        document.body.style.overflowY = "scroll";
        document.body.style.overflowX = "hidden";
        document.body.style.backgroundColor = "#F2F2F2";

        this.refreshBackgroundHeight();
        setTimeout(() => this.refreshBackgroundHeight(), 100);
    }

    refreshBackgroundHeight = () => {
        const mainWidth = this.mainRef.current.clientWidth - 1;
        this.mainRef.current.style.width  = mainWidth + "px";
        this.whiteRef.current.style.width = mainWidth + "px";
    }

    handleSearchClick = () => {
        this.props.setSearchTerms(this.state.searchTerms);
        this.props.history.push("/search-results");
    }

    updateCategoryOrOffice = (value) => {
        const filtered = this.state.searchTerms.filter(t => t.type !== 'category' && t.type !== 'office' && t.type !== 'speciality');

        this.setState({
            searchTerms: value ? [...filtered, value] : [...filtered]
        });

    }
    updateLocation = (value) => {
        const filtered = this.state.searchTerms.filter(t => t.type !== 'departement');

        this.setState({
            searchTerms: value ? [...filtered, value] : [...filtered]
        });
    }


    render() {
        const searchButtonEnabled = this.state.searchTerms.length >= 1;

        const categoriesAndOfficesValues = [
            ...this.props.categories.map(s => ({
                ...s,
                "type": "category",
            })).sort((a, b) => a.name.localeCompare(b.name)),

            ...this.props.specialities.map(speciality => ({
                "name": speciality,
                "type": "speciality",
            })),

            ...this.props.offices.map(office => ({
                ...office,
                "type": "office",
            })).sort((a, b) => a.name.localeCompare(b.name)),
        ];

        const departementsValues = this.props.departements.map(d => ({
            ...d,
            name: d.id + " - " + d.name,
            "type": "departement",
        })).sort((a, b) => a.id.localeCompare(b.id));

        const categoryOrOfficeValue = this.state.searchTerms.filter(s => s.type === 'category' || s.type === 'office'|| s.type === 'speciality')[0];
        const departementValue = this.state.searchTerms.filter(s => s.type === 'departement')[0];

        return <div ref={this.mainRef}>
            <TopBar mainText="Que cherchez-vous ?" back="/" />
            <div style={{ height: "30px", overflow: "visible", position: "absolute", width: "100%", left: 0, top: 0, zIndex: -1 }}>
                <svg style={{ width: "100%", height: "170px", position: "relative", top: "-10px" }} viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path fill="#17313A" d="M 0,0 V 50 Q 50,42 100,50 V -50"></path>
                </svg>
            </div>
            <div ref={this.whiteRef} style={{width: "100%", zIndex: "-2", backgroundColor: "white", height: "136px", position: "absolute"}}/>
            <Grid container style={{ alignItems: "stretch", backgroundColor: "", width: "90%", margin: "5%", marginTop: "40px" }} direction="row" alignItems="center" spacing={1} >
                <Grid style={{ backgroundColor: "#FFF" }} item xs={10}>
                    {!this.state.loading &&
                        <div>
                            <AutoComplete 
                                autoFocus={!categoryOrOfficeValue} 
                                values={categoriesAndOfficesValues} placeholder="Spécialité, établissement" 
                                value={categoryOrOfficeValue ? categoryOrOfficeValue.name : ""} 
                                onChange={(newValue) => this.updateCategoryOrOffice(newValue)} />
                            <AutoComplete 
                                autoFocus={!!categoryOrOfficeValue && !departementValue} 
                                values={departementsValues} placeholder="Département (Ex. 13)" 
                                value={departementValue ? departementValue.name : ""} 
                                onChange={(newValue) => this.updateLocation(newValue)} />
                        </div>
                    }
                </Grid>
                <Grid style={{ textAlign: "center", padding: "0 0 0 8px", height: "84px" }} item xs={2}>
                    <IconButton onClick={() => this.handleSearchClick()} disabled={!searchButtonEnabled} style={{ width: "100%", height: "100%", backgroundColor: searchButtonEnabled ? "#FF1C57" : "#CCCCCC", borderRadius: 0 }}>
                        <SearchIcon style={{ color: "white" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    }
}


const mapStateToProps = state => {
    return {
        categories: state.categories,
        departements: state.departements,
        offices: state.offices,
        specialities: state.specialities,
        searchTerms: state.searchTerms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDepartements: () => dispatch(fetchDepartements()),
        fetchCategories: () => dispatch(fetchCategories()),
        fetchOffices: () => dispatch(fetchOffices()),
        setSearchTerms: (searchTerms) => dispatch(setSearchTerms(searchTerms)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(SearchPage
    ));