import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HomePage from './components/HomePage';
import SearchPage from './components/SearchPage';
import Office from './components/Office';
import SearchResultsPage from './components/SearchResultsPage'
import reducers from './redux/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import settings from './settings';
import AppContainer from './AppContainer'

const loggerMiddleware = createLogger();

const middlewares = settings.env === "dev" 
  ? applyMiddleware(thunkMiddleware, loggerMiddleware)
  : applyMiddleware(thunkMiddleware);

const store = createStore(
  reducers,
  middlewares
);

function App() {
  return (
    <Provider store={store}>
        <AppContainer>
          <Router>
            <Switch>
              <Route path="/search-results"><SearchResultsPage /></Route>
              <Route path="/search"><SearchPage /></Route>
              <Route path="/office"><Office /></Route>
              <Route path="/"><HomePage /></Route>
            </Switch>
          </Router>
        </AppContainer>
    </Provider>

  );
}

export default App;
