import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import HomeSearch from './HomeSearch';
import HomeButton from './HomeButton';
import { setSearchTerms, fetchCategories, fetchDepartements, fetchOffices, REQUEST_CATEGORIES_SUCCESS, REQUEST_DEPARTEMENTS_SUCCESS, REQUEST_OFFICES_SUCCESS } from '../redux/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const styles = {
    background: {
        backgroundColor: "red",
        minHeight: "100%",
        backgroundImage: "linear-gradient(150deg, #FF1C57 0%, #8E005A 100%)",
        zIndex: -10,
        position: "absolute",
        width: "100%",
        left: 0,
    },
    round: {

    },
    mainContainer: {
        minHeight: "440px",
        marginTop: "65px",
    },
    header: {
        textAlign: "center",
        height: 90,
        marginTop: 20,
        marginBottom: 10,
        width: "100%",
        left: 0
    },
    backgroundDots: {
        position: "absolute",
        left: "28%",
        top: "-18px",
        width: "44%",
        height: "auto",
    },
    button: {
        width: "100%",
        backgroundColor: "#17313A",
        borderRadius: "2px"
    },
    buttonGrid: {
        padding: 3
    },
    roundSvg: {
        width: "100%",
        height: "50px",
        position: "relative",
        top: "-2px"
    },
    findServiceLabel: {
        color: "white",
        fontSize: 22,
        padding: 0,
        margin: 0,
        fontWeight: "bold",
        position: "relative",
        top: "-5px"
    }
};

class HomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            backendReady: false
        };
        this.headerRef = React.createRef();
        this.backgroundRef = React.createRef();
        this.mainRef = React.createRef();

        Promise.all([
            props.fetchCategories(),
            props.fetchDepartements(),
            props.fetchOffices()])
            .then(([categories, departements, offices]) => {
                if (categories.type === REQUEST_CATEGORIES_SUCCESS
                    || departements.type === REQUEST_DEPARTEMENTS_SUCCESS
                    || offices.type === REQUEST_OFFICES_SUCCESS) {
                    this.setState({
                        backendReady: true
                    });
                }
            });
    }

    refreshBackgroundHeight = () => {

        const mainHeight = this.mainRef.current.clientHeight;
        const headerHeight = this.headerRef.current.clientHeight;
        
        const windowHeight = window.innerHeight;

        this.backgroundRef.current.style.minHeight = (windowHeight) + "px";
        this.mainRef.current.style.minHeight = (windowHeight - headerHeight) + "px";

        if(windowHeight > mainHeight + headerHeight) {
            document.body.style.overflow = "hidden";
        }
        this.backgroundRef.current.style.height = mainHeight + "px";
        document.body.style.backgroundColor = "white";
    }

    componentDidMount = () => {
        this.refreshBackgroundHeight();
        setTimeout(() => this.refreshBackgroundHeight(), 100);
        setTimeout(() => this.refreshBackgroundHeight(), 500);
    }

    goToSearchPageWithCategory = (id) => {
        const category = this.props.categories.find(c => c.id === "" + id);
        this.props.setSearchTerms([{
            ...category,
            type: 'category'
        }]);
        this.props.history.push("/search");
    }


    goToSearchPage = () => {
        this.props.setSearchTerms([]);
        this.props.history.push("/search");
    }

    render() {
        return <div style={{ height: "100%" }}>
            <div  ref={this.headerRef} style={styles.header}>
                <img width={224} height={80} alt="Logo" src="/oxance-logo.svg" />
            </div>
            <div ref={this.backgroundRef} style={styles.background}>
                <svg style={styles.roundSvg} viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path fill="white" d="M 0,0 V 50 Q 50,0 100,50 V -50"></path>
                </svg>
            </div>
            <div ref={this.mainRef} style={styles.mainContainer}>
                <div style={{ padding: 10, position: "relative", top: "-10px" }}>
                    <Grid container direction="row" alignItems="center" spacing={1} >
                        <img style={styles.backgroundDots} alt="Points" src="/points.svg" />
                        <Grid item xs={12}>
                            <p style={styles.findServiceLabel} align="center">
                                Trouvez un service
                            </p>
                        </Grid>
                        <Grid style={{ marginBottom: 20 }} item xs={12}>
                            <HomeSearch disabled={!this.state.backendReady} onClick={() => this.goToSearchPage()} />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(1)} image="/medical.svg" label="Médical et Soins infirmiers" color="#17313A" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(2)} image="/dentaire.svg" label="Dentaire et Orthodontie" color="#17313A" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(3)} image="/optique.svg" label="Optique" color="#8E005A" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(4)} image="/audition.svg" label="Audition" color="#8E005A" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(5)} image="/personnes_agees.svg" label="Personne âgées" color="#FF1C57" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(6)} image="/personnes_handicapees.svg" label="Personnes handicapées" color="#FF1C57" />
                        </Grid>
                        <Grid style={styles.buttonGrid} item xs={6}>
                            <HomeButton disabled={!this.state.backendReady} onClick={() => this.goToSearchPageWithCategory(7)} image="/pharmacies.svg" label="Logements / Pharmacie" color="#FF1C57" />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = state => {
    return {
        categories: state.categories,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCategories: () => dispatch(fetchCategories()),
        fetchDepartements: () => dispatch(fetchDepartements()),
        fetchOffices: () => dispatch(fetchOffices()),
        setSearchTerms: (searchTerms) => dispatch(setSearchTerms(searchTerms)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(HomePage));