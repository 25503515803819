import React, { Component } from 'react';
import { Grid, Toolbar, IconButton, Typography, AppBar } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Home from '@material-ui/icons/Home';
import { withStyles } from '@material-ui/core/styles';
import RouterLinkRef from './RouterLinkRef';
import { withRouter } from 'react-router-dom';
import HomeIcon from './HomeIcon';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        left: "8px"
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        height: 60,
        backgroundColor: "#17313A",
        paddingRight: 0,
    }
});

class TopBar extends Component {

    goBack = () => {
        this.props.back
            ? this.props.history.push(this.props.back)
            : this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;

        return <div>
            <AppBar position="static" style={{boxShadow: "none"}} >
                <Toolbar className={classes.appBar} style={{ height: this.props.subText ? "80px" : "60px" }}>
                    <IconButton onClick={() => this.goBack()} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <ArrowBack />
                    </IconButton>
                    <Grid container style={{}} direction="row" alignItems="center" spacing={0} >
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                {this.props.mainText}
                            </Typography>
                        </Grid>
                        {this.props.subText && <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.title}>
                                {this.props.subText}
                            </Typography>
                        </Grid>}
                    </Grid>
                    <IconButton style={{ marginRight: 0, backgroundImage: "points.svg" }} component={RouterLinkRef} to="/" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <HomeIcon style={{ width: "50px", height: "50px" }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ height: this.props.subText ? "80px" : "64px", overflow: "visible", position: "absolute", width: "100%", left: 0, top: 0, zIndex: -1, backgroundColor: "#17313A" }}>
            </div>
        </div>
    }
}
export default withRouter(withStyles(styles)(
    TopBar
));